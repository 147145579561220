import * as React from "react"
// import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ServicesPage = ({ data }) => {

	const page = data.wpMainPage;
	const services = data.allWpService.nodes;

	return (
		<Layout title={page.title} breadcrumbs={[['Services','#']]}>
			<Seo post={page} />
			<section className="content" id="services">
				<h2>m1nd-set offers an unparalleled combination of expertise in:</h2>
				<div id="servicesWrapper">
					{services.map((service, i) => {
						return (
							<div key={i} className="service">
								<AniLink
									to={service.uri}
									cover bg="#EE82BF"
									direction="right"
									duration={0.8}
								>
									<div className="serviceLogo">
										{service.servicesExtra.serviceLogo !== null	?
											<GatsbyImage image={getImage(service.servicesExtra.serviceLogo.localFile)} alt="service logo" />
										: ''}
									</div>
									<div className="serviceBg">
										{service.featuredImage !== null	?
											<GatsbyImage image={getImage(service.featuredImage.node.localFile)} alt="service" />
										: ''}
									</div>
									<h3>{service.title}</h3>
								</AniLink>
							</div>
						)
					})}
				</div>

			</section>
		</Layout>
	)
}

export default ServicesPage

export const query = graphql`
	query ServicesPageQuery {
		allWpService(sort: {fields: menuOrder}) {
			nodes {
				title
				content
				uri
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				servicesExtra {
					serviceLogo {
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
		wpMainPage(databaseId: {eq: 86}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;